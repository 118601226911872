<template>
  <section
    class="min-h-screen bg-primary"
  >
    <header class="flex items-center justify-center py-10">
      <img
        src="@/assets/images/logo/rj-logo-white.svg"
        alt="RevenueJump Logo"
        class="w-48"
      >
    </header>

    <main class="w-full sm:max-w-lg py-12 px-16 mx-auto bg-white shadow-xl rounded-lg">
      <div class="space-y-8">
        <div
          v-if="loading"
          class="space-y-3 flex justify-center"
        >
          One moment please...
        </div>
        <div
          v-if="partner"
          class="space-y-3 flex justify-center"
        >
          <div class="flex-col">
            <h1 class="text-center pb-2">
              Thank you!
            </h1>
            <p>You've granted {{ partner.name }} permission to send surveys on your behalf.</p>
          </div>
        </div>
        <div
          v-if="errorMessage"
          class="space-y-3 flex justify-center"
        >
          <p>{{ errorMessage }}</p>
        </div>
        <div class="space-y-3 flex justify-center">
          <vs-button
            color="primary"
            type="filled"
            class="mt-2"
            @click="$router.replace({ name: 'login' })"
          >
            Open Dashboard
          </vs-button>
        </div>
      </div>
    </main>

    <footer class="text-center py-10 text-white text-sm opacity-50">
      <p>&copy; {{ new Date().getFullYear() }} RevenueJump. All rights reserved.</p>
      <a
        href="https://www.revenuejump.com/privacy/"
        target="_blank"
        class="block mt-1 hover:text-white hover:underline"
      >
        Privacy Pledge
      </a>
    </footer>
  </section>
</template>

<script>
import { rjky } from '@/ky';
import { colors } from '@/../themeConfig';

export default {
  name: 'RjPartnerSurveyPermissionPage',

  data() {
    return {
      company: null,
      errorMessage: '',
      partner: null,
      success: false,
      loading: true,
    };
  },

  computed: {
    colors() {
      return colors;
    },
  },

  watch: {
    $route: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch() {
      const { token } = this.$route.params;
      if (!token) {
        return;
      }
      try {
        const { success, partner, company } = await rjky.get(`companies/approveSurveyPermissionRequest?token=${token}`).json();
        if (success || company.partnerCanSendSurveys) {
          this.partner = partner;
          this.success = true;
        } else {
          this.errorMessage = 'Invalid link.';
        }
      } catch (error) {
        const response = await error.response.json();
        if (response.errorMessage) {
          this.errorMessage = response.errorMessage;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
