import Auth from '@/http/middleware/Authenticate';

export default {
  name: 'RjHomeView',

  middleware: [Auth],

  computed: {
    role() {
      return this.$store.getters['auth/currentRole'];
    },

    companyRoles() {
      return this.$store.getters['roles/companyRoles'];
    },

    partnerRoles() {
      return this.$store.getters['roles/partnerRoles'];
    },

    adminRoles() {
      return this.$store.getters['roles/adminRoles'];
    },
  },

  watch: {
    $route: {
      handler: 'redirect',
      immediate: true,
    },
  },

  render: (h) => h(),

  methods: {
    async redirect() {
      const companyDestination = this.companyRoles.reduce((map, role) => ({
        ...map, [role.name]: { name: 'company-home' },
      }), {});

      const partnerDestination = this.partnerRoles.reduce((map, role) => ({
        ...map, [role.name]: { name: 'partner-home' },
      }), {});

      const adminDestination = this.adminRoles.reduce((map, role) => ({
        ...map, [role.name]: { name: 'admin-home' },
      }), {});

      const destination = { ...adminDestination, ...partnerDestination, ...companyDestination };

      return this.$router.replace(destination[this.role]);
    },
  },
};
