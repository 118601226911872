<template>
  <section
    v-show="company"
    class="min-h-screen bg-primary"
  >
    <header class="flex items-center justify-center py-10">
      <img
        src="@/assets/images/logo/rj-logo-white.svg"
        alt="RevenueJump Logo"
        class="w-48"
      >
    </header>

    <main class="w-full sm:max-w-lg py-12 px-16 mx-auto bg-white shadow-xl rounded-lg">
      <img
        :src="logo"
        :alt="company ? `${company.name} Logo` : 'Logo'"
        class="block w-48 mx-auto"
      >

      <div class="flex items-center justify-center my-12 space-x-6">
        <feather-icon
          icon="CheckCircleIcon"
          svg-classes="w-16 h-16"
          :style="{ color: colors.rjGreen }"
        />
        <h1 class="text-3xl font-bold">
          {{ company ? company.name : 'Company' }} <br>
          is verified by RevenueJump
        </h1>
      </div>

      <div class="space-y-8">
        <div class="space-y-3">
          <h3>Are the testimonials and/or reviews valid?</h3>
          <p>
            All displayed testimonials and reviews are captured by RevenueJump directly
            from the company’s customer or through the indicated review sites.
          </p>
        </div>

        <div class="space-y-3">
          <h3>How does RevenueJump capture the testimonials and reviews?</h3>
          <p>
            Through a RevenueJump survey, the company asks their customers for their testimonials.
            For reviews, RevenueJump connects directly to the review site (i.e. Google).
            RevenueJump then stores all available testimonial and review data on our secure servers.
            This data may include the customer’s quote, rating, posting date, and if available,
            the profile image of the customer.
          </p>
        </div>

        <div class="space-y-3">
          <h3>Can this company fake the testimonials and reviews shown?</h3>
          <p>
            Since RevenueJump captures reviews published by third-party review sites,
            our data is as reliable as each review site’s data. As a result, RevenueJump relies on
            each review site to filter out fake reviews prior to our capturing them. Since testimonials do not
            involve a third-party site, the company is under agreement with RevenueJump to not load fake
            testimonials onto our system. In some cases, this action may be considered illegal.
          </p>
        </div>

        <div class="space-y-3">
          <h3>How do I get RevenueJump for my business?</h3>
          <p>
            The website widget, as we call it, is only one of many features available from RevenueJump.
            To see how it works,
            <a
              href="https://www.revenuejump.com/funnels/"
              target="_blank"
              class="text-primary font-medium hover:underline"
            >click here</a>.
            Or if you’re ready to get started,
            <a
              href="https://www.revenuejump.com/get-started/"
              target="_blank"
              class="text-primary font-medium hover:underline"
            >click here</a>.
          </p>
        </div>
      </div>
    </main>

    <footer class="text-center py-10 text-white text-sm opacity-50">
      <p>&copy; {{ new Date().getFullYear() }} RevenueJump. All rights reserved.</p>
      <a
        href="https://www.revenuejump.com/privacy/"
        target="_blank"
        class="block mt-1 hover:text-white hover:underline"
      >
        Privacy Pledge
      </a>
    </footer>
  </section>
</template>

<script>
import { rjky } from '@/ky';
import { colors } from '@/../themeConfig';

export default {
  name: 'RjVerifiedPage',

  data() {
    return {
      company: null,
    };
  },

  computed: {
    logo() {
      if (this.company !== null) {
        const { id, logo } = this.company;
        if (logo && /https?:\/\//.test(logo)) {
          return logo;
        }
        if (logo) {
          return `${process.env.VUE_APP_RJ_COMPANY_LOGO_URL}/${id}/${logo}`;
        }
        return null;
      }
      return null;
    },

    colors() {
      return colors;
    },
  },

  watch: {
    $route: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch() {
      this.company = await rjky.get(`companies/${this.$route.params.company}/getBasicCompanyInfo`).json();
    },
  },
};
</script>
