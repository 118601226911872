<template>
  <full-page>
    <div class="h-screen flex w-full bg-img">
      <div
        class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
      >
        <img
          src="@/assets/images/pages/404.png"
          alt="graphic-404"
          class="mx-auto mb-4 max-w-full"
        >
        <h1 class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color">
          Unsubscribe
        </h1>
        <p class="sm:mx-0 mx-4 mb-4 sm:mb-16 text-lg">
          You have successfully unsubscribed.
        </p>
      </div>
    </div>
  </full-page>
</template>

<script>
import FullPage from '@/layouts/full-page/FullPage.vue';
import { rjky } from '@/ky';

export default {
  name: 'RjUnsubscribe',

  components: { FullPage },

  watch: {
    $route: {
      handler: 'fetch',
      immediate: true,
    },
  },

  methods: {
    async fetch() {
      const { contactId, locationId } = this.$route.params;
      if (!contactId || !locationId) {
        return;
      }
      try {
        await rjky.post('contacts/unsubscribe', {
          json: { contactId: parseInt(contactId, 10), locationId: parseInt(locationId, 10) },
        }).json();
      } catch (error) {
        //
      }
    },
  },
};
</script>
